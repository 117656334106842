<script setup lang="ts">
import { API_GET_ALL_TAGS, API_GET_TAG_GAMES } from '@/api'
import type { ITagBlockProps } from '@/types/components'
import { toTitleCase } from '@/utils'

import SVG_GAME_INTRO from '@/assets/svg/game-intro.svg'

import type { ITagsGameResponseData } from '@/types/api'

const currentTagGameList = ref<ITagsGameResponseData | null>()
const allTagsList = ref<ITagBlockProps[] | null>()
const { params: { name = 'all' } } = useRoute()
const { t } = useI18n()

const isAllTags = ref(name.toString().toLocaleLowerCase() === 'all')

useCacheRoute()

const tagDetail = computed(() => currentTagGameList.value?.category?.length ? currentTagGameList.value?.category[0] : undefined)
const tagName = computed(() => tagDetail.value?.labelKey ? t(tagDetail.value.labelKey) : toTitleCase(name as string))
const title = computed(() => {
  return `${isAllTags.value ? t('title.allTag') || `All Game Categories` : t(`title.tag`, { tagName: tagName.value }) || `Play ${tagName.value} Games Online for Free | EasyFun.gg`}`
})
const popGameList = computed(() => currentTagGameList.value?.games.map(v => v.gameName).splice(0, 3).join(','))
const description = computed(() => {
  if (isAllTags.value)
    return t('description.allTag') || `Here you can find all the games' categories on EasyFun.gg, find and play your best mobile or web games now!`

  let _ = `Experience the best ${tagName.value} games on EasyFun.gg in browser for free. No download required, bigger screen ${tagName.value} gaming experience!`
  if (currentTagGameList.value?.games?.length)
    _ += ` Play ${popGameList} right now!`
  return t('description.tag', { tagName: tagName.value, popGameList: popGameList.value }) || _
})

const qaList = computed(() => {
  return [
    {
      q: t('tag.faqTemplate1q1', { tagName: tagName.value }),
      a: t('tag.faqTemplate1a1', { tagName: tagName.value, popGameList: popGameList.value }),
    },
    {
      q: t('tag.faqTemplate1q2', { tagName: tagName.value }),
      a: t('tag.faqTemplate1a2', { tagName: tagName.value }),
    },
    {
      q: t('tag.faqTemplate1q3', { tagName: tagName.value }),
      a: t('tag.faqTemplate1a3', { tagName: tagName.value, popGameList: popGameList.value }),
    },
    {
      q: t('tag.faqTemplate1q4', { tagName: tagName.value }),
      a: t('tag.faqTemplate1a4', { tagName: tagName.value }),
    },
    {
      q: t('tag.faqTemplate1q5', { tagName: tagName.value }),
      a: t('tag.faqTemplate1a5', { tagName: tagName.value }),
    },
  ]
})

if (!isAllTags.value) {
  const { value, addToCache } = await useDataCache<ITagsGameResponseData>(`api:tag_games_${name}`)
  const { data } = await useAsyncData<ITagsGameResponseData>('tag-games', async () => {
    if (value)
      return value
    const response = await API_GET_TAG_GAMES(name as string)
    await addToCache(response, ['api-data'], 900)
    return response
  })

  if (!data.value?.category?.length)
    throw createError({ statusCode: 404, statusMessage: 'Page Not Found' })

  currentTagGameList.value = data.value
}
else {
  const { value, addToCache } = await useDataCache<ITagBlockProps[]>(`api:all_tags`)
  const { data } = await useAsyncData<ITagBlockProps[]>('all-tags', async () => {
    if (value)
      return value
    const response = await API_GET_ALL_TAGS()
    await addToCache(response, ['api-data'], 900)
    return response
  })

  allTagsList.value = data.value
}

useSeoMeta({
  title,
  ogTitle: title,
  description: description.value,
  ogDescription: description.value,
})
</script>

<template>
  <div class="view-content">
    <section class="mt-20 flex-center flex-col">
      <div class="flex-center">
        <!-- <img v-if="tagDetail?.icon" class="mie-3 size-8" :src="tagDetail?.icon" alt=""> -->
        <h1 v-if="!isAllTags" class="m-0 text-8 c-#fff font-[Bebas_Neue] uppercase lt-md:text-center">
          {{ $t('tag.tagTitle', { tagName }) || `Play ${tagName} games Online` }}
        </h1>
        <h1 v-else class="m-0 text-8 c-#fff font-[Bebas_Neue] uppercase">
          {{ tagName }}
        </h1>
      </div>
      <p class="m-0 mt-3 text-3.5 c-#fff c-op-50 lh-5">
        {{ $t('tag.tagIntro', { tagName }) || `Experience ${tagName} games with EasyFun online instantly. Free, No lag, No downloads required, Eojoy your ${tagName} games now !` }}
      </p>
    </section>

    <section v-if="!isAllTags && currentTagGameList?.games.length" class="grid mt-12" gap="4 md:6" grid-cols="lt-md:4 md:6 lg:8">
      <CommonGameLite v-for="game in currentTagGameList?.games" :key="game.gameId" :game="game" />
    </section>

    <section v-if="!isAllTags" class="mt-20">
      <!-- Intro -->
      <div v-if="tagDetail?.directions" class="flex items-center">
        <SVG_GAME_INTRO class="mie-2" />
        <span class="text-6 c-#fff c-op-90 font-[Bebas_Neue] uppercase">{{ $t('tag.tagDetailTitle', { tagName }) || `Eojoy the best ${tagName} Games Online` }}</span>
      </div>
      <div v-if="tagDetail?.directions" class="mt-4 c-#fff c-op-50" v-html="tagDetail.directions" />

      <!-- FAQ -->
      <div v-if="qaList[0].q" class="mt-20 flex-col">
        <CommonFaqList :qa-list="qaList" />
      </div>
    </section>
    <section v-else class="m-x-auto mt-20 max-w-228.5 w-full flex flex-wrap justify-center gap-4">
      <CommonTagBlock v-for="v in allTagsList" :key="v.id" v-bind="v" />
    </section>
  </div>
</template>
